/**
  Sang Bleu Sans font
*/

@font-face {
  font-family: 'SangBleuSans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("SangBleuSans-Light-WebS.woff") format("woff"),
    url("SangBleuSans-Light-WebS.woff2") format("woff2"),
    url("SangBleuSans-Light-WebS.eot") format("eot"),
    url("SangBleuSans-Light-WebS.svg") format("svg"),
    url("SangBleuSans-Light-WebS.ttf") format("ttf");
}

@font-face {
  font-family: 'SangBleuSans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("SangBleuSans-Regular-WebS.woff") format("woff"),
    url("SangBleuSans-Regular-WebS.woff2") format("woff2"),
    url("SangBleuSans-Regular-WebS.eot") format("eot"),
    url("SangBleuSans-Regular-WebS.svg") format("svg"),
    url("SangBleuSans-Regular-WebS.ttf") format("ttf");
}