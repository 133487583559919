/**
  Inter font
*/

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-Light.woff2") format("woff2"),
       url("Inter-Light.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Regular.woff2") format("woff2"),
       url("Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBold.woff2") format("woff2"),
       url("Inter-SemiBold.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-Bold.woff2") format("woff2"),
       url("Inter-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Italic.woff2") format("woff2"),
       url("Inter-Italic.woff") format("woff");
}